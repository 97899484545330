import React, { useEffect } from 'react';
import Wallet from '../../components/wallet';
import Footer from '../../components/footer';
import { useTranslation } from 'react-i18next';
import Header from '../../menu/header';
import { StyledHeader } from '../../Styles';
// import { useAppKit } from '@reown/appkit/react';

const theme = 'GREY'; //LIGHT, GREY, RETRO

function Wallets() {
  const {t}=useTranslation();
  // const { open } = useAppKit();

  useEffect(() => {
    // open();
  }, []);
  
  return (
    <div>
    <StyledHeader theme={theme} />
    <Header className=""/>
      <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
        <div className='mainbreadcumb'>
          <div className='container'>
            <div className='row m-10-hor'>
              <div className='col-12'>
                <h1 className='text-center'>{t("Wallets")}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className='container'>
        <Wallet/>
      </section>
    
      <Footer />
    </div>
  )
}

export default Wallets