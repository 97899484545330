import { useEffect, useState } from 'react';
import Footer from '../../components/footer';
import Header from '../../menu/header';
import { StyledHeader } from '../../../MarkePlace/Styles';
import { epicSSO } from '../../../../services/AuthService';
import * as thunkActions from '../../../../store/actions/thunks';
import * as actions from '../../../../store/actions';
import * as selectors from '../../../../store/selectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EPATHS } from '../../../../routes/paths.enum';
import EpicRegisterResultModal from '../../../Modals/EpicRegisterResultModal';

function EpicSSO() {
  const theme = 'HOME';
	const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const user = JSON.parse(localStorage.getItem('_user'));
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const signedUser = useSelector(selectors.user);
  const history=useHistory();
	
	useEffect(() => {
		if(code) {
      actions.setLoading(true);
			epicSSO({authCode: code}).then(res => {
        localStorage.removeItem('_first');
        thunkActions.setUser({...user, epicUsername: res.epicUsername, epicUserId: res.epicUserId});
        localStorage.setItem('_user', JSON.stringify({...user, epicUsername: res.epicUsername, epicUserId: res.epicUserId}));
        actions.setLoading(false);
        setOpen(true);
        setIsSuccess(true);
        // history.push({pathname: EPATHS.HOME});
			}).catch(err => {
        localStorage.removeItem('_first');
        actions.setLoading(false);
        let msg = (typeof err === 'object') ? err.message : err;
        setOpen(true);
        setIsSuccess(false);
        setErrMsg(msg);
			});
		}
	}, [code]);
	
  return (
    <div>
      <StyledHeader theme={theme} />
      <Header className="HOME"/>
      <section style={{height: 600}}></section>
      <EpicRegisterResultModal
        open={open}
        onClose={() => {
          setOpen(false);
          history.push({pathname: EPATHS.HOME});
        }}
        isSuccess={isSuccess}
        errMsg={errMsg}
      />
      <Footer />
    </div>
  )
}

export default EpicSSO;