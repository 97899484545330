import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button, Typography } from '@material-ui/core';
import useStyles from "../../styles/components/Servererror/modalError";

function EpicRegisterModal({open, onClose, onSubmit}) {
  const classes=useStyles();
  const [step, setStep] = useState(0);

  const EPIC_CLIENT_ID = process.env.REACT_APP_EPIC_CLIENT_ID;
  const EPIC_REDIRECT_URI = `${process.env.REACT_APP_URL}/epic-sso`;

  const epicSignin = () => {
      localStorage.removeItem('_first');
      const authUrl = `https://www.epicgames.com/id/authorize?client_id=${EPIC_CLIENT_ID}&response_type=code&redirect_uri=${EPIC_REDIRECT_URI}&scope=basic_profile%20friends_list%20presence%20country&prompt=consent`;
      window.location.href = authUrl;
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className='alert-modal'
    >
      <div className={classes.root}>
        <div className={classes.topBlock}>
            <HighlightOffIcon className={classes.icon} onClick={() => { 
              onClose();
            }} />
        </div>  
        {step === 0 ? <div className={classes.bottomBlock}>
            <Typography className={classes.igwTitle} style={{marginTop: 50, textAlign: 'center'}}>
              {`Have you already created an Earniverse account on the Epic Games Store?`}
            </Typography>
            <div className={classes.inputBlock} style={{marginTop: 50}}>
              <Button className={classes.igwClaimSubmit} color="primary" variant="contained" onClick={() => setStep(1)} >{'Yes'}</Button>
              <Button className={classes.igwClaimSubmit} color="secondary" variant="contained" onClick={() => setStep(2)} >{'No'}</Button>
            </div>
        </div> : 
          <>
            {step === 1 ? <div className={classes.bottomBlock}>
              <Typography className={classes.igwTitle} style={{marginTop: 50, textAlign: 'center'}}>
                {`Click on the button below to link your Earnimarket account to Earniverse.`}
              </Typography>
              <div className={classes.inputBlock} style={{marginTop: 50}}>
                <Button className={classes.igwClaimSubmit} color="primary" variant="contained" onClick={() => epicSignin()} >{'Link my account'}</Button>
              </div>
            </div> : <div className={classes.bottomBlock}>
              <Typography className={classes.igwTitle} style={{marginTop: 50, textAlign: 'center'}}>
                {`Start enjoying Earniverse Metaverse experience by linking your Earnimarket account to Epic Games Store.`}
              </Typography>
              <div className={classes.inputBlock} style={{display: 'block', textAlign: 'center'}}>
                <Button className={classes.igwClaimSubmit} color="primary" variant="contained" onClick={() => epicSignin()} >{'Create my Earniverse account'}</Button>
                <Button className={classes.igwClaimSubmit} color="secondary" variant="contained" onClick={() => onClose()} >{'Do it later'}</Button>
              </div>
            </div>
          }
          </>
        }
      </div>
    </Dialog>
  )
}

export default EpicRegisterModal;
