import { EPATHS } from '../../routes/paths.enum';
import axios from 'axios';
import { signin, signout } from "../../services/AuthService";

const serverURL = process.env.REACT_APP_SERVER;

export const login = async(dispatch, setLoading, setUser, history, setServerError=null) => {
    if (window.ethereum) {
        dispatch(setLoading(true));
        if (window.ethereum.networkVersion !== Number(process.env.REACT_APP_AVAX_CHAIN_ID).toString()) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: process.env.REACT_APP_AVAX_CHAIN_ID }]
                });
            } catch (err) {}
            dispatch(setLoading(false));
        }

        dispatch(setLoading(true));

        try {
        } catch (error) { dispatch(setLoading(false));}
        // const auth= await authenticate({signingMessage:"Earniverse Authentication"});
        let result = {};
        await window.ethereum
            .request({ method: 'eth_accounts' })
            .then(async (accounts) => {
                if(accounts.length !== 0) {
                    try {
                        dispatch(setLoading(true));
                        const user = await signin({metamask: accounts[0]});
                        await localStorage.setItem('_user', JSON.stringify(user));
                        if(!user.epicUserId && !user.epicUsername) {
                            localStorage.setItem('_first', true);
                        }
                        dispatch(setUser(user));
                        dispatch(setLoading(false));
                        result = user;
                        // history.push({pathname:EPATHS.EXPLORE});
                        // if(isRedireact) {
                         history.push({pathname:EPATHS.HOME});
                        // }
                        return result;
                    } catch (error) {
                        const err = JSON.parse(error.message)
                        if(err.status === 404) {
                            dispatch(setLoading(false));
                            history.push({pathname:EPATHS.REGISTER});
                        }
                    }
                }
            })
            .catch((error) => {console.log('err ', error)});
        dispatch(setLoading(false));
        return result;
    } else {
        setServerError(true);
        return null;
    }
}


export const logout = async(dispatch, setUser, history) => {
    // disconnect();
    await signout();
    localStorage.removeItem('_user');
    localStorage.removeItem('_first');
    dispatch(setUser({}));
    history.push({pathname: EPATHS.HOME});
    return;
}
