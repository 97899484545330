export enum EPATHS{
    HOME="/",
    MARKETPLACE="/matketplace",
    MAPS="/maps",
    MAP="/map",
    TILE_MAP="/tile-map",
    LAND="/land",
    EXPLORE="/explore",
    AUTHORS="/authors",
    AUTHOR="/author/:id",
    WALLET="/wallet",
    MY_PROFILE="/myProfile",
    EDIT_PROFILE="/editProfile",
    GAIN="/gain",
    COUPON="/coupon",
    COUPON_DETAIL="/coupon/:id",
    CREATE_OPTIONS="/createOptions",
    CREATE_MULTIPLE="/createMultiple",
    REGISTER="/register",
    USDC_AVAX="/usdc-avax",
    PROFILE_MANAGEMENT="/profilesManagement",
    CREATE_PROFILE="/createProfile",
    LOGIN="/login",
    ASSET_DETAIL="/3D_Asset/:nftId",
    LAND_DETAIL="/land/:landId",
    LAND_GROUP_DETAIL="/land-group/:landGroupId",
    NFT_DETAIL="/3D_NFT/:nftId/:itemId?",
    EDIT_ITEM="/3D_Asset/edit/:nftId",
    SETTINGS="/setting",
    MY_COLLECTIONS='/collection',
    MY_BIDS='/bids',
    MY_COLLECTION_DETAIL="/collection/:assetId",
    MY_COLLECTION_HISTORY='/collection/:assetId/history',
    MY_NFTS='/myNfts',
    GET_STARTED='/getstarted',
    NOTIFICATIONS='/notifications',
    TRANSACTIONS='/transactions',
    ADMIN_ACTION='/admin-action',
    IN_GAME_WALLET='/in-game-wallet',
    RESET_PASSWORD='/reset-password',
    CONFIRM_PASSWORD='/confirm-password',
    CHANGE_EMAIL='/change-verification',
    EPIC_SSO='/epic-sso',
}