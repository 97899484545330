import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useTranslation } from 'react-i18next';

function PaypalModal({onClose, onSuccess, couponPrice}) {
  const { t } = useTranslation();

  return (
    <div className='checkout'>
			<div className='maincheckout'>
				<button
					className='btn-close'
					onClick={() => onClose()}
				>
					x
				</button>
				<div className='heading'>
					<h3>{"Checkout"}</h3>
				</div>
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
          <div>
            <PayPalButtons
              style={{ layout: 'vertical' }}
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: couponPrice, // Set the price of the product
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                  onSuccess();
                  // Handle the transaction success, like updating the state or redirecting
                });
              }}
              onError={(err) => {
                console.error('PayPal Error:', err);
                // Handle any error that occurs during the payment process
              }}
            />
          </div>
        </PayPalScriptProvider>
      </div>
    </div>
  )
}

export default PaypalModal;