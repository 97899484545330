import React from 'react';
import { memo, useState, useRef, useEffect } from 'react';
import Web3 from "web3";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import Clock from "./Clock";
// import { navigate } from '@reach/router';
import api from '../../../core/api';
import { NavLink, useHistory } from 'react-router-dom';
// import Nft3dCard from '../../../components/Cards/nft3DCard';
import { Typography,Dialog,Button } from '@material-ui/core';
import useStyles from '../../../styles/components/Cards/nftCard';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
// import ModalError from '../../../components/Servererror/modalError';
// import { EPATHS } from '../../../routes/paths.enum';
import * as selectors from '../../../store/selectors';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../store/actions/thunks';
import * as setActions from '../../../store/actions';
import ModalNoMetamask from "../../../components/Servererror/modalNoMetamask";
import { login } from "../../../utils/functions/authenticate";
import { setTileLike,
    setTileDislike,
    setTileWishlist,
    setTileDiswishlist
} from '../../../services/TileService';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const TileCard = ({nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad, account, asset }) => {
    const {t}=useTranslation();
    const history=useHistory();
    const classes=useStyles();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [actionType, setActionType] = useState();
    const [countLike, setCountLike] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [render, setRender] = useState(false);
    const signedUser = useSelector(selectors.user);
    const authorsState = useSelector(selectors.authorsState);
    const authors = authorsState.data ? authorsState.data : [];

    const notificationsState = useSelector(selectors.notificationsState);
    const handleRedirectToItem=(id)=>{
        history.push({pathname:`/land/${id}`})
        localStorage.setItem("id",id)
    }

    const isVoted = useRef(false);
    const isWishlisted = useRef(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if(nft.TileLikes) {
            for(let i=0; i<nft.TileLikes.length; i++) {
                if(signedUser?.metamask === nft.TileLikes[i].User?.metamask?.toLowerCase()) {
                    isVoted.current = true;
                }
            }
        }

        if(nft.TileWishlists) {
            for(let i=0; i<nft.TileWishlists.length; i++) {
                if(signedUser?.metamask === nft.TileWishlists[i].User?.metamask?.toLowerCase()) {
                    isWishlisted.current = true;
                }
            }
        }

        if(nft.TileLikes) {
            setCountLike(nft?.TileLikes?.length);
        }
    }, [nft]);

    useEffect(() => {
        setRender(!render);
    }, [isVoted.current, isWishlisted.current]);

    const handleLike = async (userData) => {
        setActionType();
        if(nft.TileLikes) {
            for(let i=0; i<nft.TileLikes.length; i++) {
                if(userData.metamask === nft.TileLikes[i].User.metamask.toLowerCase()) {
                    isVoted.current = true;
                }
            }
        }

        if(!isVoted.current) {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setTileLike(nft?.id);
                let totalLikes = res.likes;
                setCountLike(totalLikes);
                setShowAlertModal(true);
                setAlertMessage('Your like has been added.');
                isVoted.current = true;
                dispatch(actions.fetchNotifications());
                dispatch(actions.fetchAssetLikeBreakdown(userData.id, 0));
            } catch (e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
        else {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setTileDislike(nft.id);
                setCountLike(res.likes);
                dispatch(actions.fetchNotifications());
                setShowAlertModal(true);
                isVoted.current = false;
                setAlertMessage('Your like has been removed.');
                dispatch(actions.fetchAssetLikeBreakdown(userData.id, 0));
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
    }

    const onClickLike = () => {
        if(signedUser.id) {
            handleLike(signedUser);
        }
        else {
            setShowModal(true);
            setActionType('like');
        }
    }

    const handleWishlist = async (userData) => {
        setActionType();

        if(nft.TileWishlists) {
            for(let i=0; i<nft.TileWishlists.length; i++) {
                if(userData.metamask === nft.TileWishlists[i].User.metamask.toLowerCase()) {
                    isWishlisted.current = true;
                }
            }
        }

        if(!isWishlisted.current) {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setTileWishlist(nft?.id);
                dispatch(actions.fetchNotifications());
                setShowAlertModal(true);
                isWishlisted.current = true;
                setAlertMessage('This Asset has been added to your wishlist.');
                dispatch(actions.fetchAssetWishlistBreakdown(userData.id, 0));
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
        else {
            dispatch(setActions.setLoading(true));
            try {
                const res = await setTileDiswishlist(nft.id);
                dispatch(actions.fetchNotifications());
                setShowAlertModal(true);
                isWishlisted.current = false;
                setAlertMessage('This Asset has been removed from your wishlist.');
                dispatch(actions.fetchAssetWishlistBreakdown(userData.id, 0));
            } catch(e) {
                alert(e);
            }
            dispatch(setActions.setLoading(false));
        }
    }

    const onClickBasket = () => {
        if(signedUser.id) {
            handleWishlist(signedUser);
        }
        else {
            setShowModal(true);
            setActionType('wishlist');
        }
    }

    const handleAuth = async () => {
        const userData = await login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
        setShowModal(false);
        if(userData && actionType === 'like') {
            handleLike(userData);
        }
        if(userData && actionType === 'wishlist') {
            handleWishlist(userData);
        }
    }

    return (
        <div className={className}>
            <div className="nft__item m-0">
            { nft.item_type === 'single_items' ? (
             <div className='icontype'><i className="fa fa-bookmark"></i></div>   
             ) : (  
             <div className={isWishlisted.current ? "icontype_selected" :"icontype"}><i className="fa fa-shopping-basket" onClick={() => onClickBasket()}></i></div>
                )
            }
                { nft.deadline && clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft.deadline} />
                    </div>
                }
                <div className="author_list_pp">
                {/* onClick={()=> navigateTo(nft.author_link)} */}
                    { nft?.User && nft?.User?.picture ?
                        <span >
                            <img className="lazy" src={nft.User ? `${api.baseUrl}/${nft.User.picture}` : ""} alt="" onClick={() => {
                                if(authors.findIndex(e => e.id === nft?.User?.id) > -1)
                                    history.push({pathname:`/author/${nft.User.id}`});
                                }
                            }/>
                            <i className="fa fa-check"></i>
                        </span>
                        :
                        <span >
                            <img className="lazy" src="/img/author/author-11.png" alt="" onClick={() => {
                                if(authors.findIndex(e => e.id === nft?.User?.id) > -1)
                                    history.push({pathname:`/author/${nft.User.id}`});
                            }}/>
                        </span>
                    }
                </div>
                <div className="nft__item_wrap" style={{height: `${height}px`, position: 'relative'}}>
                <Outer>
                    <span>
                        <NavLink to={`/land/${nft.id}`}>
                            <img onLoad={onImgLoad} src={api.ipfsURL + nft.image} className="lazy nft__item_preview" alt=""  />
                        </NavLink>
                    </span>
                </Outer>
                </div>
                { nft.deadline && !clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft?.deadline} />
                    </div>
                }
                <div className="nft__item_info">
                    {/* <span onClick={() => navigateTo(`${nft.nft_link}/${nft.id}`)}> */}
                    <NavLink to={`/land/${nft.id}`}>
                        <span>
                            <h4>{nft?.Continent?.name}</h4>
                            {nft.minted && <p className={classes.cardPrice}>{"Item ID: #" + nft?.itemId}</p>}
                            <p className={classes.cardPrice}>{"PosX: " + nft.posX}</p>
                            <p className={classes.cardPrice}>{"PosY: " + nft.posY}</p>
                            <p className={classes.cardPrice}>{"PosZ: " + nft.posZ}</p>
                            <p className={classes.tierTitle}><b>{nft?.Tier.name}</b></p>
                        </span>
                    </NavLink>
                    { nft.status !== 'has_offers' &&
                    // { nft.status !== 'has_offers' && !account &&
                        <div className="nft__item_price">
                            <NavLink to={`/land/${nft.id}`}>
                                <p className={classes.cardPrice}>
                                { nft?.ownerId === signedUser?.id ?
                                    "You own this parcel" 
                                        :(nft?.isInfrastructure ?
                                            "Not for Sale"
                                        : (
                                        nft.isForSale 
                                            ? `${Web3.utils.fromWei(nft?.price ? nft?.price : nft?.Tier?.price, 'gwei') * 1000} USDC`
                                            : "Not for Sale"
                                    )
                                    )
                                }
                                </p>
                            </NavLink>
                            {/* <p className={classes.cardPrice} onClick={() => handleRedirectToItem(nft.id)}>{(Web3.utils.fromWei(nft.price, 'gwei') * priceRate * 1000).toFixed(2)} EIV</p> */}
                            {/* <p className={classes.cardPrice} onClick={() => handleRedirectToItem(nft.id)}>0 USDT</p> */}
                        </div>}
                    {/* { nft && user && nft?.User?.metamask !== user?.get('ethAddress') && <div className="nft__item_action">
                        <span onClick={() => handleRedirectToItem(nft.id)}>{ nft.status === 'on_auction' ? t("PlaceBid") : t("BuyNow") }</span>
                    </div>} */}
                    <div className={isVoted.current ? "nft__item_liked" :"nft__item_like"}>
                        <i className="fa fa-heart" onClick={() => onClickLike()}></i><span>{countLike}</span>
                    </div>
                </div> 
            </div>
            <Dialog
                open={showModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='alert-modal'
            >
                <div className={classes.root}>
                    <div className={classes.topBlock}>
                        <CheckCircleOutlinedIcon className={classes.icon} />
                    </div>  
                    <div className={classes.bottomBlock}>
                        <Typography className={classes.title}>
                            {t("You need to connect your wallet first!")}
                        </Typography>
                        <div className={classes.buttonBlock}>
                            <button
                                className="btn-main"
                                onClick={()=>setShowModal(false)}
                            >
                                {t("Close")} 
                            </button>
                            <button
                                className="btn-main"
                                onClick={handleAuth}
                            >
                                {t("Connect")}
                            </button>
                            
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={showAlertModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='alert-modal'
            >
                <div className={classes.root}>
                    <div className={classes.topBlock}>
                        <CheckCircleOutlinedIcon className={classes.icon} />
                    </div>  
                    <div className={classes.bottomBlock}>
                        <Typography className={classes.title}>
                            {alertMessage}
                        </Typography>
                        <div className={classes.buttonCancelBlock}>
                            <button
                                className="btn-main"
                                onClick={()=>{setShowAlertModal(false)}}
                            >
                                {t("Close")} 
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
            <ModalNoMetamask open={serverError} onClose={() => {setServerError(false);}}/>
        </div>             
    );
};

export default memo(TileCard);