import { useTranslation } from 'react-i18next';
import USDC from "../../assets/images/USDC.webp";
import CARD from "../../assets/images/credit.webp";
import CARD_NOTAVAILABLE from "../../assets/images/credit-card-3d-not-available.png";

function SelectPaymentMethod({onClose, handleBuyItem, paymentMethod, setPaymentMethod, disabled, isAvailable}) {
	const { t } = useTranslation();

	return (
		<div className='checkout'>
			<div className='maincheckout'>
				<button
					className='btn-close'
					onClick={() => onClose()}
				>
					x
				</button>
				<div className='heading'>
					<h3>{"Select Payment Method"}</h3>
				</div>
				<div className='d-flex' style={{justifyContent: 'space-around', textAlign: 'center'}}>
					<div style={paymentMethod === 1 ? { cursor: 'pointer', minWidth: 180, border: 'solid 2px #a706aa', borderRadius: 10 } : { cursor: 'pointer', minWidth: 180 }} onClick={() => setPaymentMethod(1)}>
						<img src={USDC} alt="" style={{width: 100, marginTop: 30}}/>
						<p style={{color: 'black', fontWeight: 500, marginTop: 39}}><i>{"USDC (on Avax)"}</i></p>
					</div>
					<div style={paymentMethod === 2 ? { cursor: 'pointer', minWidth: 180, border: 'solid 2px #a706aa', borderRadius: 10 } : {cursor: 'pointer', minWidth: 180}} onClick={() => setPaymentMethod(2)}>
						{isAvailable 
						? <img src={CARD} alt="" style={{width: 160}}/>
						: <img src={CARD_NOTAVAILABLE} alt="" style={{width: 160}} />
						}
						<p className="mt-2" style={{color: 'black', fontWeight: 500, marginBottom: 0}}><i>{"Fiat Currency"}</i></p>
						{!isAvailable && <p style={{fontSize: 12}}>{"(Not available)"}</p>}
					</div>
				</div>
				<button disabled={paymentMethod === 0 || disabled} className='btn-main lead mb-5' onClick={() => { handleBuyItem() }}>{"Next"}</button>
			</div>
		</div>
	)
}

export default SelectPaymentMethod;