import { axiosWithToken } from '../utils/functions/apiCallWithToken';

const serverURL = process.env.REACT_APP_SERVER;

export const fetchTileBids = async () => {
    const result = await axiosWithToken(`${serverURL}/tile/bid/`, 'get', {});
    return result;
}

export const fetchTileGroupBids = async () => {
    const result = await axiosWithToken(`${serverURL}/tile/group/bid/`, 'get', {});
    return result;
}

export const fetchTiles = async (params) => {
    const result = await axiosWithToken(`${serverURL}/tile/`, 'get', {} , params);
    return result;
}

export const fetchTile = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/${id}/`, 'get', {});
    return result;
}

export const fetchTileGroups = async (params) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/`, 'get', {} , params);
    return result;
}

export const fetchTileGroup = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/${id}/`, 'get', {});
    return result;
}

export const fetchTileLike = async (page) => {
    const result = await axiosWithToken(`${serverURL}/tile/like/?page=${page}`, 'get', {});
    return result;
}

export const fetchTileWishlist = async (page) => {
    const result = await axiosWithToken(`${serverURL}/tile/wishlist/?page=${page}`, 'get', {});
    return result;
}

export const mintTile = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/mint/${id}/`, 'put', data);
    return result;
}

export const mintTileWithPaypal = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/mint-paypal/${id}/`, 'put', data);
    return result;
}

export const addTileListing = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/add-listing/${id}/`, 'put', data);
    return result;
}

export const cancelTileListing = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/cancel-listing/${id}/`, 'put', data);
    return result;
}

export const buyTile = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/buy/${id}/`, 'put', data);
    return result;
}

export const buyTileWithPaypal = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/buy-paypal/${id}/`, 'put', data);
    return result;
}

export const admintTile = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/admint/${id}/`, 'post', data);
    return result;
}

export const updateTileMetadata = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/update-metadata/${id}/`, 'post', {});
    return result;
}

export const admintTileGroup = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/admint/${id}/`, 'post', data);
    return result;
}

export const mintTileGroup = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/mint/${id}/`, 'put', data);
    return result;
}

export const mintTileGroupWithPaypal = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/mint-paypal/${id}/`, 'put', data);
    return result;
}

export const addTileGroupListing = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/add-listing/${id}/`, 'put', data);
    return result;
}

export const cancelTileGroupListing = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/cancel-listing/${id}/`, 'put', data);
    return result;
}

export const buyTileGroup = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/buy/${id}/`, 'put', data);
    return result;
}

export const buyTileGroupWithPaypal = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/buy-paypal/${id}/`, 'put', data);
    return result;
}

export const transferTile = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/transfer/${id}/`, 'put', data);
    return result;
}

export const transferTileGroup = async (id, data) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/transfer/${id}/`, 'put', data);
    return result;
}

export const fetchMissingTiles = async () => {
    const result = await axiosWithToken(`${serverURL}/tile/missing/tiles/`, 'get', {});
    return result;
}

export const addMissingTransaction = async (data) => {
    const result = await axiosWithToken(`${serverURL}/tile/missing/add/`, 'post', data);
    return result;
}

export const updateTileTransaction = async (data) => {
    const result = await axiosWithToken(`${serverURL}/tile/trasaction/`, 'put', data);
    return result;
}

export const setTileLike = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/like/${id}/`, 'post', {});
    return result;
}

export const setTileDislike = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/dislike/${id}/`, 'delete', {});
    return result;
}

export const setTileWishlist = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/wishlist/${id}/`, 'post', {});
    return result;
}

export const setTileDiswishlist = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/dis-wishlist/${id}/`, 'delete', {});
    return result;
}

export const setTileGroupLike = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/like/${id}/`, 'post', {});
    return result;
}

export const setTileGroupDislike = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/dislike/${id}/`, 'delete', {});
    return result;
}

export const setTileGroupWishlist = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/wishlist/${id}/`, 'post', {});
    return result;
}

export const setTileGroupDiswishlist = async (id) => {
    const result = await axiosWithToken(`${serverURL}/tile/group/dis-wishlist/${id}/`, 'delete', {});
    return result;
}