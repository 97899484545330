import React from 'react';
import Web3 from "web3";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import * as selectors from '../../../../store/selectors';

import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';
import { convertDateFormat } from '../../../../utils/functions/convertDateFormat';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'react-notifications-component/dist/theme.css';
import ModalGain from "../../../../components/Servererror/modalGain";
import ModalSnapShotRegister from "../../../../components/Servererror/modalSnapshotConfirm";
import Footer from '../../components/footer';
import MarketplaceAbi from "../../../../artifacts/contracts/Marketplace.sol/Marketplace.json";
import SnapshotAbi from "../../../../artifacts/contracts/MarketplaceSnapshot.sol/MarketplaceSnapshot.json";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Notification from "../../../../utils/functions/notification";
import enableWeb3 from "../../../../utils/functions/enableWeb3";
import * as actions from '../../../../store/actions/thunks';
import * as setActions from '../../../../store/actions';
import * as TransactionService from "../../../../services/TransactionService";
import * as SnapshotService from "../../../../services/SnapshotService";
import * as AuthService from "../../../../services/AuthService";
import * as DataService from "../../../../services/DataService";
import Header from "../../menu/header";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#30166e',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
  }))(TableCell);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    greenField: {
        color: 'green'
    },
    redField: {
        color: 'red'
    },
    transferButtonBlock: {
      display: "flex",
      marginTop: 10,
  },
});
  
function Gain() {
    const { t } = useTranslation();
    const web3 = new Web3(Web3.givenProvider);
    const dispatch = useDispatch();
    const marketplaceAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const snapshotAddress = process.env.REACT_APP_SNAPSHOT_CONTRACT;
    const companyAddress = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;
    const atWalletAddress = process.env.REACT_APP_AT_WALLET_ADDRESS;
    const marketplaceContract = new web3.eth.Contract(MarketplaceAbi.abi, marketplaceAddress);
    const snapshotContract = new web3.eth.Contract(SnapshotAbi.abi, snapshotAddress);

    const [isActive, setActive] = useState(false);
    const [supplyShare, setSupplyShare] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [revenueClaimed, setRevenueClaimed] = useState(0);
    const [revenueRemaining, setRevenueRemaining] = useState(0);

    const [holdersTotalRevenue, setHoldersTotalRevenue] = useState(0);
    const [totalClaimable, setTotalClaimable] = useState(0);
    const [marketplaceTotalClaimed, setMarketplaceTotalClaimed] = useState(0);
    const [marketplaceTotalToken, setMarketplaceTotalToken] = useState(0);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openRegisterConfirm, setOpenRegisterConfirm] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('Please wait...')
    const [txData, setTxData] = useState([]);
    const [snapshot, setSnapshot] = useState();
    const [numberRegistered, setNumberRegistered] = useState(0);
    const signedUser = useSelector(selectors.user);
    const classes = useStyles();
    const profitTypes = ['SALE', 'COMMISSION', 'ROYALTY', 'AUTHOR ROYALTY', 'INFLUENCER', 'CONTACT'];
    const totalTokenNumber = 10000000000;

    useEffect(() => {
        if(signedUser.id) {
            fetchRevenueData();
            SnapshotService.fetchSnapshot(signedUser.metamask.toLowerCase()).then(res => setSnapshot(res));
        }
        // eslint-disable-next-line
    }, [signedUser.id]);

    useEffect(() => {
        if(snapshot) {
            fetchRevenueData();
        }
    }, [snapshot]);

    const fetchRevenueData = () => {
        TransactionService.fetchRevenueTransaction().then(res => {
            setTxData(res);
        });
        if(signedUser.metamask === companyAddress.toLowerCase()) {
            DataService.fetchNumberOfUsers().then((res) => {
                setNumberRegistered(res);
            })
            snapshotContract.methods.getTotalBalance().call({from: signedUser.metamask}).then(res => {
                const totalToken = totalTokenNumber - Web3.utils.fromWei(res, 'mwei');
                setMarketplaceTotalToken(totalToken);
            });
            snapshotContract.methods.getTotalRevenuesForHolders(marketplaceAddress).call({from: signedUser.metamask}).then(res => {
                setHoldersTotalRevenue(Web3.utils.fromWei(res, 'mwei'));
            });
            snapshotContract.methods.claimable(marketplaceAddress).call({from: signedUser.metamask}).then(res => {
                setTotalClaimable(Web3.utils.fromWei(res, 'mwei'));
            });
            snapshotContract.methods.getMarketplaceClaimed().call({from: signedUser.metamask}).then(res => {
                setMarketplaceTotalClaimed(Web3.utils.fromWei(res, 'mwei'));
            });
        }
        else {
            snapshotContract.methods.claimable(marketplaceAddress).call({from: signedUser.metamask}).then(res => {
                setTotalClaimable(Web3.utils.fromWei(res, 'mwei'));
            });
            snapshotContract.methods.calculateRevenue(marketplaceAddress).call({from: signedUser.metamask}).then(res => {
                setSupplyShare(Web3.utils.fromWei(res.balance, 'mwei'));
                // setTotalRevenue(Web3.utils.fromWei(res.claimed.add(res.claimable), 'mwei'));
                setTotalRevenue(parseFloat(Web3.utils.fromWei(res.claimed, 'mwei')) + parseFloat(Web3.utils.fromWei(res.claimable, 'mwei')));
                setRevenueClaimed(Web3.utils.fromWei(res.claimed, 'mwei'));
                setRevenueRemaining(Web3.utils.fromWei(res.claimable, 'mwei'));
            });
        }
    }

    const registerSnapshot = () => {
        setActive(true);
        setOpenRegisterConfirm(false);
        SnapshotService.manualRegister({wallet: signedUser.metamask}).then(res => {
            setActive(false);
            setSnapshot(res);
            setSupplyShare(res.balance);
            dispatch(actions.fetchNotifications());
            Notification('Congratulations! Your wallet address is now included for the gains claim portal.', true);
        }).catch(error => {
            let msg = (typeof error === 'object') ? error.message : error;
            setActive(false);
            Notification(msg, false);
        })
    }

    const onSubmit = async() => {
        dispatch(setActions.setLoading(true));
        AuthService.sessionCheck().then(async (res) => {
            dispatch(setActions.setLoading(false));
            setActive(true);
            const enabled = await enableWeb3();
            if (!enabled) {
                Notification('Waiting for metamask sign in.', false);
                return
            }
            setOpenConfirm(false);
            setLoadingMsg('');
            try {
                const account = signedUser.metamask;
                if(signedUser.metamask === companyAddress.toLowerCase()) {
                    const txRes = await marketplaceContract.methods.withdrawClaimablesByOwner(atWalletAddress).send({from: account});
                    const metadata = {
                        txHash: txRes.transactionHash,
                        price: Web3.utils.toWei(Number((totalClaimable - holdersTotalRevenue - marketplaceTotalClaimed).toFixed(6)).toString(), 'mwei'),
                        type: true
                        // priceInUSDT: Math.round(totalAmount * priceRate),
                    }
                    await TransactionService.claim(metadata);
                }
                else {
                    const txRes = await marketplaceContract.methods.withdrawRevenue().send({from: account});
                    const metadata = {
                        txHash: txRes.transactionHash,
                        price: Web3.utils.toWei(revenueRemaining, 'mwei'),
                        type: true
                        // priceInUSDT: Math.round(totalAmount * priceRate),
                    }
                    await TransactionService.claim(metadata);
                }
                fetchRevenueData();
                setActive(false);
                setOpenConfirm(false);
                Notification(`Your USDCs are now available!`, true);
            } catch (error) {
                let msg = (typeof error === 'object') ? error.message : error;
                setActive(false);
                Notification(msg, false);
            }
        })
        .catch(err => {
            dispatch(setActions.setLoading(false));
            if(err.response?.status == '401') {
                dispatch(actions.setIsTimeout(true));
            }
        })
    }

    return (
        <div>
            <Header className=""/>
            <ReactNotifications />
            <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
                <div className='mainbreadcumb'>
                    <div className='container'>
                        <div className='row m-10-hor'>
                            <div className='col-12'>
                                <h1 className='text-center'>{t("MyGains")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LoadingOverlay
                active={isActive}
                spinner
                text={loadingMsg}
                className='centeredOverlay'>
            {(snapshot?.isRegistered || signedUser?.metamask === companyAddress.toLowerCase()) ? <section className="container">
                <div className='row'>
                    <>
                        <TableContainer className="mb-6" component={Paper} style={{maxHeight: 500, padding: 0, marginBottom: 30}}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>#</StyledTableCell>
                                        <StyledTableCell>Type of Tx</StyledTableCell>
                                        <StyledTableCell>Tx Detail</StyledTableCell>
                                        <StyledTableCell>Price (USDC)</StyledTableCell>
                                        {(signedUser.roleId == 1 || signedUser.roleId == 2) &&<StyledTableCell>Commission</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>Royalty (USDC)</StyledTableCell>}
                                        {(signedUser.roleId == 1 || signedUser.roleId == 2) &&<StyledTableCell>Author Royalty (USDC)</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>Influencer (USDC)</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>Contact (USDC)</StyledTableCell>}
                                        <StyledTableCell>USDC Total</StyledTableCell>
                                        <StyledTableCell>From</StyledTableCell>
                                        {/* <StyledTableCell>To</StyledTableCell> */}
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Tx</StyledTableCell>
                                        <StyledTableCell>Remaining Gains (USDC)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {txData.length > 0 ? <>{txData.map((tx, index) => {
                                    return (
                                    <TableRow key={index}>
                                        <StyledTableCell>{txData.length - index}</StyledTableCell>
                                        <StyledTableCell className={profitTypes.includes(tx.typeOfTx) ? classes.greenField : (!tx.sent && tx.typeOfTx === 'MANUAL' ? classes.greenField : (tx.typeOfTx === 'ADMINT' ? classes.greenField : classes.redField))}>{tx.typeOfTx}</StyledTableCell>
                                        <StyledTableCell><a href={tx.link} target='_blank'><u>{tx.categoryWithId}</u></a></StyledTableCell>
                                        <StyledTableCell>{tx.price ? Web3.utils.fromWei(tx.price, 'mwei') : tx.price}</StyledTableCell>
                                        {(signedUser.roleId == 1 || signedUser.roleId == 2) && <StyledTableCell>{tx.commission ? parseFloat(Web3.utils.fromWei(parseFloat(tx.commission).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>{tx.royalty ? parseFloat(Web3.utils.fromWei(parseFloat(tx.royalty).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        {(signedUser.roleId == 1 || signedUser.roleId == 2) && <StyledTableCell>{tx.authorRoyalty ? parseFloat(Web3.utils.fromWei(parseFloat(tx.authorRoyalty).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>{tx.influencer ? parseFloat(Web3.utils.fromWei(parseFloat(tx.influencer).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>{tx.contact ? parseFloat(Web3.utils.fromWei(parseFloat(tx.contact).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        <StyledTableCell className={profitTypes.includes(tx.typeOfTx) ? classes.greenField : (!tx.sent && tx.typeOfTx === 'MANUAL' ? classes.greenField : classes.redField)}>{tx.total ? parseFloat(Web3.utils.fromWei(parseFloat(tx.total).toString(), 'mwei')).toFixed(3) : tx.total}</StyledTableCell>
                                        <StyledTableCell>{tx.sender?.username}</StyledTableCell>
                                        {/* <StyledTableCell>{tx.recipient.username}</StyledTableCell> */}
                                        <StyledTableCell>{convertDateFormat(tx.createdAt.replace('T', ' ').replace('.000Z', ''))} (UTC)</StyledTableCell>
                                        <StyledTableCell>{<a href={tx.txLink} target="_blank"><u>View Tx</u></a>}</StyledTableCell>
                                        {signedUser?.metamask !== companyAddress.toLowerCase() ? 
                                            <StyledTableCell className={revenueRemaining <= 0 ? classes.redField : classes.greenField}>{revenueRemaining}</StyledTableCell> :
                                            <StyledTableCell className={tx.totalAmount <= 0 ? classes.redField : classes.greenField}>{tx.totalAmount}</StyledTableCell>
                                        }
                                    </TableRow>)
                                })}</> : <TableRow>
                                    <StyledTableCell colSpan={14}><p>No transaction data exists.</p></StyledTableCell></TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </> 
                    {signedUser?.metamask !== companyAddress.toLowerCase() ? <>
                        <div className='col-12'>
                            <p><strong>Marketplace total revenues:</strong> {totalClaimable}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>My token holdings:</strong> {supplyShare}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>My supply shares:</strong> {supplyShare / totalTokenNumber * 100}%</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>My total revenues:</strong> {Number(totalRevenue.toFixed(10))}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Revenues Already Claimed:</strong> {revenueClaimed}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Revenues remaining:</strong> {revenueRemaining}</p>
                        </div>
                    </> : <>
                        <div className='col-12'>
                            <p><strong>Number of Registered Users:</strong> {numberRegistered}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Marketplace total tokens:</strong> {marketplaceTotalToken}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Marketplace supply shares:</strong> {marketplaceTotalToken / totalTokenNumber * 100}%</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Marketplace total revenues:</strong> {totalClaimable}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Marketplace holders revenues:</strong> {holdersTotalRevenue}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Revenues to claim:</strong> {Number((totalClaimable - holdersTotalRevenue).toFixed(6))}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Revenues already claimed:</strong> {marketplaceTotalClaimed}</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Revenues Remaining:</strong> {Number((totalClaimable - holdersTotalRevenue - marketplaceTotalClaimed).toFixed(10))}</p>
                        </div>
                    </>}
                </div>
                <div className="row">
                    <div className='col-4'>
                        <input type="button" id="submit"
                            disabled={
                                (revenueRemaining <= 0 && signedUser.metamask !== companyAddress.toLowerCase()) 
                                || (Number((totalClaimable - holdersTotalRevenue - marketplaceTotalClaimed).toFixed(10)) <= 0 && signedUser.metamask === companyAddress.toLowerCase())
                                // || txData[0]?.totalAmount <= 0
                            }
                            className="btn-main"
                            style={{marginTop: 32}}
                            value="Claim Funds"
                            onClick={() => setOpenConfirm(true)}
                        />
                    </div>
                </div>
            </section>
            : (snapshot && signedUser?.metamask && <section className="container" style={{textAlign: 'center'}}>
                <h2>CONGRATULATIONS!</h2>
                <p>Your Wallet address is included in Earniverse Holders Snapshot. To be able to claim your gains from Earniverse Marketplace, you will need to register this wallet address as the beneficiary.</p>
                <input type="button" className="btn-main" style={{marginTop: 32, marginRight: 'auto', marginLeft: 'auto'}} value="Register My Wallet" onClick={() => setOpenRegisterConfirm(true)} />

            </section>)}
            {openConfirm && <ModalGain onClose={() => setOpenConfirm(false)} onSubmit={onSubmit} value={signedUser.metamask === companyAddress.toLowerCase() ? Number((totalClaimable - holdersTotalRevenue - marketplaceTotalClaimed).toFixed(10)) : revenueRemaining}/>}

            {openRegisterConfirm && <ModalSnapShotRegister onClose={() => setOpenRegisterConfirm(false)} onSubmit={registerSnapshot} />}
            </LoadingOverlay>
            <Footer />
        </div>
    )
}

export default Gain