import axios from 'axios';
import crypto from 'crypto-browserify';

const SYMMETRIC_KEY = process.env.REACT_APP_SYMMETRIC_KEY;
const symmetricKey = Buffer.from(SYMMETRIC_KEY.toString('hex'), 'hex');
const server3dntechURL = process.env.REACT_APP_3DNTECH_SERVER;

export async function axiosWithToken(url, method, body={}, params={}) {
	const user = JSON.parse(localStorage.getItem('_user'));
	let result = [];
	if(user && user.accessToken) {
		axios.defaults.headers.common["Authorization"] = "Bearer " + user.accessToken;
	}

	if(url.includes('/auth') && axios.defaults.headers.common["Authorization"]) {
		if(!url.includes('session-check') && !url.includes('signout') && !url.includes('user/') && !url.includes('reset-password') && !url.includes('epic')) {
			delete axios.defaults.headers.common["Authorization"];
		}
	}

	const iv = crypto.randomBytes(16);
	const cipher = crypto.createCipheriv('aes-256-gcm', symmetricKey, iv);
	let encryptedBody = cipher.update(JSON.stringify(body), 'utf-8', 'base64');
	encryptedBody += cipher.final('base64');
	const tag = cipher.getAuthTag();

	await axios({
		method: method,
		url: url,
		data: {
			iv: iv.toString('hex'),
			data: encryptedBody.toString('hex'),
			authTag: tag.toString('hex'),
		},
		params: {...params, timestamp: Date.now()},
	})
	.then(data => {
		// const privateKey = process.env.REACT_APP_ASYMMETRIC_PRIVATE_KEY;
		// const encryptedKey = Buffer.from(data.data.key, 'hex');
		// const decryptedSymmetricKey = crypto.privateDecrypt(
		// 	{
		// 	  key: privateKey.replace(/\\n/g, '\n'),
		// 	  padding: crypto.constants.RSA_PKCS1_PADDING,
		// 	},
		// 	encryptedKey
		// );
		const decipher = crypto.createDecipheriv('aes-256-gcm', symmetricKey, Buffer.from(data.data.iv, 'hex'));
		decipher.setAuthTag(Buffer.from(data.data.authTag, 'hex'));
		let decryptedData = decipher.update(data.data.data, 'base64', 'utf-8');
		decryptedData += decipher.final('utf-8');
		result = JSON.parse(decryptedData);
	}).catch(error => {
		if(error.response) {
			// eslint-disable-next-line
			if(error.response.status == '401') {
				if(!url.includes('/session-check')) {
					localStorage.removeItem('_user');
					window.location.href = '/';
				}
			}
			else if(error.response.status === 404) {
				if(url.includes('/auth/signin')) {
					const decipher = crypto.createDecipheriv('aes-256-gcm', symmetricKey, Buffer.from(error.response.data.iv, 'hex'));
					decipher.setAuthTag(Buffer.from(error.response.data.authTag, 'hex'));
					let decryptedData = decipher.update(error.response.data.data, 'base64', 'utf-8');
					decryptedData += decipher.final('utf-8');
					result = JSON.parse(decryptedData);
					result['status'] = error.response.status;
					throw new Error(JSON.stringify(result));
				}
				else {
					window.location.href = '/';
				}
			}
			else {
				const decipher = crypto.createDecipheriv('aes-256-gcm', symmetricKey, Buffer.from(error.response.data.iv, 'hex'));
				decipher.setAuthTag(Buffer.from(error.response.data.authTag, 'hex'));
				let decryptedData = decipher.update(error.response.data.data, 'base64', 'utf-8');
				decryptedData += decipher.final('utf-8');
				result = JSON.parse(decryptedData);
				if (url.includes('/auth/signin')) {
					result['status'] = error.response.status;
					throw new Error(JSON.stringify(result));
				}
				throw new Error(result?.message ? result?.message : 'Server error');
			}
		}
		throw error
	});

	return result;	
}

export async function axiosWithoutEncryption(url, method, body={}, params={}) {
	const user = JSON.parse(localStorage.getItem('_user'));
	let result = [];
	if(user && user.accessToken) {
		axios.defaults.headers.common["Authorization"] = "Bearer " + user.accessToken;
	}

	if(url.includes('/auth') && axios.defaults.headers.common["Authorization"]) {
		if(!url.includes('signout') && !url.includes('user/')) {
			delete axios.defaults.headers.common["Authorization"];
		}
	}

	await axios({
		method: method,
		url: url,
		data: body,
		params: {...params, timestamp: Date.now()},
	})
	.then(data => {
		result = data.data;
		if(url.includes(server3dntechURL)) {
			return result;
		}
		const decipher = crypto.createDecipheriv('aes-256-gcm', symmetricKey, Buffer.from(data.data.iv, 'hex'));
		decipher.setAuthTag(Buffer.from(data.data.authTag, 'hex'));
		let decryptedData = decipher.update(data.data.data, 'base64', 'utf-8');
		decryptedData += decipher.final('utf-8');
		result = JSON.parse(decryptedData);
	}).catch(error => {
		if(url.includes(server3dntechURL)) {
			throw new Error(error.response.data.message);
		}
		const decipher = crypto.createDecipheriv('aes-256-gcm', symmetricKey, Buffer.from(error.response.data.iv, 'hex'));
		decipher.setAuthTag(Buffer.from(error.response.data.authTag, 'hex'));
		let decryptedData = decipher.update(error.response.data.data, 'base64', 'utf-8');
		decryptedData += decipher.final('utf-8');
		result = JSON.parse(decryptedData);
		throw new Error(result.message);
	});

	return result;
}