import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../components/footer';
import { StyledHeader } from '../../Styles';
import Web3 from "web3";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Header from "../../menu/header";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LoadingOverlay from 'react-loading-overlay-ts';
import MarketplaceAbi from "../../../../artifacts/contracts/Marketplace.sol/Marketplace.json";
import SnapshotAbi from "../../../../artifacts/contracts/MarketplaceSnapshot.sol/MarketplaceSnapshot.json";
import { ReactNotifications } from 'react-notifications-component';
import { convertDateFormat } from '../../../../utils/functions/convertDateFormat';

import * as TransactionService from "../../../../services/TransactionService";
import * as AuthService from "../../../../services/AuthService";
import Notification from "../../../../utils/functions/notification";
import enableWeb3 from "../../../../utils/functions/enableWeb3";
import * as actions from '../../../../store/actions/thunks';
import * as setActions from '../../../../store/actions';
import * as selectors from '../../../../store/selectors';

const theme = 'GREY'; //LIGHT, GREY, RETRO

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#30166e',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
  }))(TableCell);
  
const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    greenField: {
        color: 'green'
    },
    redField: {
        color: 'red'
    },
    transferButtonBlock: {
      display: "flex",
      marginTop: 10,
  },
});
  
function Transactions() {
    const marketplaceAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const snapshotAddress = process.env.REACT_APP_SNAPSHOT_CONTRACT;
    const earniverseAddress = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;

    const [type, setType] = useState(0);
    const [period, setPeriod] = useState(0);
    const [dateRange, onChangeDateRange] = useState([new Date(), new Date()]);
    const [txData, setTxData] = useState([]);
    // const [priceRate, setPriceRate] = useState(0);
    const [isActive, setActive] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);

    const web3 = window.ethereum ? new Web3(Web3.givenProvider) : null;

    const txTypes = ['ALL', 'BUY', 'SALE', 'CLAIM', 'MANUAL', 'COMMISSION', 'ROYALTY', 'AUTHOR ROYALTY', 'INFLUENCER', 'CONTACT'];
    const profitTypes = ['SALE', 'COMMISSION', 'ROYALTY', 'AUTHOR ROYALTY', 'INFLUENCER', 'CONTACT'];
    const classes = useStyles();
    const dispatch = useDispatch();
    const signedUser = useSelector(selectors.user);

    useEffect(() => {
        

        // fetchPriceRate();
        // const timer = setTimeout(() => {
        //     fetchPriceRate();
        // }, 60000);

        // return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
      if(signedUser.metamask) {
        const marketplaceContract = new web3.eth.Contract(MarketplaceAbi.abi, marketplaceAddress);
        const snapshotContract = new web3.eth.Contract(SnapshotAbi.abi, snapshotAddress);
        let account = signedUser.metamask;

        if (earniverseAddress.toLowerCase() === account) {
            // account = marketplaceAddress;
            let claimable = 0;
            // marketplaceContract.methods.claimableAmount(account).call().then(res => {
            //     claimable += parseFloat(res);
            //     marketplaceContract.methods.claimableAmount(signedUser.metamask).call().then(res => {
            //         claimable += parseFloat(res);
            //         setTotalAmount(claimable.toString());
            //     });
            // });

            snapshotContract.methods.claimable(account).call().then(res => {
                setTotalAmount(res);
            });
        } else {
            snapshotContract.methods.claimable(account).call().then(res => {
                setTotalAmount(res);
            });
        }

        const params = {};

        TransactionService.fetchTransaction(params).then(res => {
            if(signedUser.metamask === earniverseAddress.toLowerCase()) {
                const filteredRes = res.filter(tx => tx.typeOfTx !== 'ROYALTY' && tx.typeOfTx !== 'COMMISSION' && tx.typeOfTx !== 'CLAIM REVENUES');
                setTxData(filteredRes);
            }
            else {
                const filteredRes = res.filter(tx => tx.typeOfTx !== 'ROYALTY' && tx.typeOfTx !== 'COMMISSION' && tx.typeOfTx !== 'CLAIM REVENUES');
                setTxData(filteredRes);
            }
        });
      }
    }, [signedUser.metamask]);

    // const fetchPriceRate = () => {
    //     DataService.fetchExchangeData().then((res => {
    //       setPriceRate(res);
    //     }));
    // }

    const handleFilter = () => {
        const params = {};
        if(type !== 0) {
            params['type'] = txTypes[type];
        }
        if(period === 1) {
            var date = new Date();
            params['start'] = new Date(date.getFullYear(), date.getMonth(), 1);
            params['end'] = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        }
        if(period === 2) {
            params['start'] = dateRange[0];
            params['end'] = dateRange[1];
        }
        
        TransactionService.fetchTransaction(params).then(res => {
            if(signedUser.metamask === earniverseAddress.toLowerCase()) {
                const filteredRes = res.filter(tx => tx.typeOfTx !== 'ROYALTY' && tx.typeOfTx !== 'COMMISSION' && tx.typeOfTx !== 'CLAIM REVENUES');
                setTxData(filteredRes);
            }
            else {
                const filteredRes = res.filter(tx => tx.typeOfTx !== 'ROYALTY' && tx.typeOfTx !== 'COMMISSION' && tx.typeOfTx !== 'CLAIM REVENUES');
                    setTxData(filteredRes);
            }
        })
    }

    const handleClaim = async() => {
        dispatch(setActions.setLoading(true));
        AuthService.sessionCheck().then(async (res) => {
            dispatch(setActions.setLoading(false));
            const marketplaceContract = new web3.eth.Contract(MarketplaceAbi.abi, marketplaceAddress);
            const snapshotContract = new web3.eth.Contract(SnapshotAbi.abi, snapshotAddress);

            const enabled = await enableWeb3();
            const params = {};
            if (!enabled) {
                Notification('Waiting for metamask sign in.', false);
                return
            }
            setActive(true);
            const account = signedUser.metamask;

            try {
                let txRes;
                if(signedUser.metamask == earniverseAddress.toLowerCase()) {
                    txRes = await marketplaceContract.methods.withdrawClaimables().send({from: account});
                    // const withdrawnEvent = txRes.events.RevenueWithdrawn;
                    // console.log('price: ', withdrawnEvent.returnValues.price, '============', 'claimer: ', withdrawnEvent.returnValues.claimer);
                }
                else {
                    txRes = await marketplaceContract.methods.withdrawClaimables().send({from: account});
                }

                const metadata = {
                    txHash: txRes.transactionHash,
                    price: totalAmount,
                    // priceInUSDT: Math.round(totalAmount * priceRate),
                }

                await TransactionService.claim(metadata);

                dispatch(actions.fetchNotifications());
                TransactionService.fetchTransaction(params).then(res => {
                    if(signedUser.metamask === earniverseAddress.toLowerCase()) {
                        const filteredRes = res.filter(tx => tx.typeOfTx !== 'ROYALTY' && tx.typeOfTx !== 'COMMISSION' && tx.typeOfTx !== 'CLAIM REVENUES');
                        setTxData(filteredRes);
                    }
                    else {
                        const filteredRes = res.filter(tx => tx.typeOfTx !== 'ROYALTY' && tx.typeOfTx !== 'COMMISSION' && tx.typeOfTx !== 'CLAIM REVENUES');
                        setTxData(filteredRes);
                    }
                })
                snapshotContract.methods.claimable(account).call().then(res => {
                    setTotalAmount(res);
                });
                setActive(false);
                Notification(`You claimed ${(Web3.utils.fromWei(totalAmount, 'gwei') * 1000).toFixed(3)} USDC tokens.`, true);
                setShowConfirm(false);
            } catch (error) {
                let msg = (typeof error === 'object') ? error.message : error;
                setActive(false);
                Notification(msg, false);
                setShowConfirm(false);
            }
        })
        .catch(err => {
            dispatch(setActions.setLoading(false));
            if(err.response?.status == '401') {
                dispatch(actions.setIsTimeout(true));
            }
        })
    }

    const { t } = useTranslation();
    return (
        <div>
            <LoadingOverlay
                style={{zIndex: 3000}}
                active={isActive}
                spinner
                text={'Please wait while you are claiming.'}
            >
                <StyledHeader theme={theme} />
                <ReactNotifications />
                <Header className=""/>
                <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
                    <div className='mainbreadcumb'>
                        <div className='container'>
                            <div className='row m-10-hor'>
                                <div className='col-12'>
                                    <h1 className='text-center'>Transactions</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='container'>
                    <div className='row'>
                        <div className='col-lg-12' style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                            <Select
                                value={period}
                                onChange={(e) => setPeriod(e.target.value)}
                                variant="outlined"
                                style={{minWidth: 200}}
                            >
                                <MenuItem value={0}>All Transactions</MenuItem>
                                <MenuItem value={1}>This Month</MenuItem>
                                <MenuItem value={2}>Period</MenuItem>
                            </Select>
                            <Select
                                className='ml-20'
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                variant="outlined"
                                label="Type"
                                style={{minWidth: 200}}
                            >
                                <MenuItem value={0}>All</MenuItem>
                                <MenuItem value={1}>Sale</MenuItem>
                                <MenuItem value={2}>Buy</MenuItem>
                                <MenuItem value={3}>Claim</MenuItem>
                            </Select>
                            {(period === 2) && <DateRangePicker className='ml-20 transaction-range-picker-wrapper' onChange={onChangeDateRange} value={dateRange} />}
                            <span onClick={() => handleFilter()} className="btn-main ml-20">Filter</span>
                        </div>
                    </div>
                    <React.Fragment>
                        <TableContainer component={Paper} style={{maxHeight: 500}}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>#</StyledTableCell>
                                        <StyledTableCell>Type of Tx</StyledTableCell>
                                        <StyledTableCell>Tx Detail</StyledTableCell>
                                        <StyledTableCell>Price (USDC)</StyledTableCell>
                                        {(signedUser.roleId == 1 || signedUser.roleId == 2) &&<StyledTableCell>Commission</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>Royalty (USDC)</StyledTableCell>}
                                        {(signedUser.roleId == 1 || signedUser.roleId == 2) &&<StyledTableCell>Author Royalty (USDC)</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>Influencer (USDC)</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>Contact (USDC)</StyledTableCell>}
                                        <StyledTableCell>My Gain</StyledTableCell>
                                        <StyledTableCell>From</StyledTableCell>
                                        {/* <StyledTableCell>To</StyledTableCell> */}
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Tx</StyledTableCell>
                                        <StyledTableCell>Type of Payment</StyledTableCell>
                                        <StyledTableCell>Total to Claim (USDC)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {txData.map((tx, index) => {
                                    return (
                                    <TableRow key={index}>
                                        <StyledTableCell>{txData.length - index}</StyledTableCell>
                                        <StyledTableCell className={profitTypes.includes(tx.typeOfTx) ? classes.greenField : (!tx.sent && tx.typeOfTx === 'MANUAL' ? classes.greenField : (tx.typeOfTx === 'ADMINT' ? classes.greenField : classes.redField))}>{tx.typeOfTx}</StyledTableCell>
                                        <StyledTableCell><a href={tx.category === 'NFT' ? `/3D_NFT/${tx.Nft?.id}/${tx.Nft?.itemId}` : tx.link} target='_blank'><u>{tx.category === 'NFT' ? `${tx.Asset?.title} #${tx.Nft?.itemId}` : (tx.category === 'LAND' ? `LAND #${tx.Tile?.itemId}` : (tx.category === 'LANDGROUP' ? `LANDGROUP #${tx.TileGroup?.itemId}` : tx.categoryWithId))}</u></a></StyledTableCell>
                                        <StyledTableCell>{tx.price ? Web3.utils.fromWei(tx.price, 'mwei') : tx.price}</StyledTableCell>
                                        {(signedUser.roleId == 1 || signedUser.roleId == 2) && <StyledTableCell>{tx.commission ? parseFloat(Web3.utils.fromWei(parseFloat(tx.commission).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>{tx.royalty ? parseFloat(Web3.utils.fromWei(parseFloat(tx.royalty).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        {(signedUser.roleId == 1 || signedUser.roleId == 2) && <StyledTableCell>{tx.authorRoyalty ? parseFloat(Web3.utils.fromWei(parseFloat(tx.authorRoyalty).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>{tx.influencer && tx.isCrypto ? parseFloat(Web3.utils.fromWei(parseFloat(tx.influencer).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        {signedUser.roleId == 1 && <StyledTableCell>{tx.contact && tx.isCrypto ? parseFloat(Web3.utils.fromWei(parseFloat(tx.contact).toString(), 'mwei')).toFixed(3) : 0}</StyledTableCell>}
                                        <StyledTableCell className={profitTypes.includes(tx.typeOfTx) ? classes.greenField : (!tx.sent && tx.typeOfTx === 'MANUAL' ? classes.greenField : classes.redField)}>{tx.total ? parseFloat(Web3.utils.fromWei(parseFloat(tx.total).toString(), 'mwei')).toFixed(3) : tx.total}</StyledTableCell>
                                        <StyledTableCell>{tx.sender?.username}</StyledTableCell>
                                        {/* <StyledTableCell>{tx.recipient.username}</StyledTableCell> */}
                                        <StyledTableCell>{convertDateFormat(tx.createdAt.replace('T', ' ').replace('.000Z', ''))} (UTC)</StyledTableCell>
                                        <StyledTableCell>{tx.isCrypto && <a href={tx.txLink} target="_blank"><u>View Tx</u></a>}</StyledTableCell>
                                        <StyledTableCell>{tx.isCrypto ? 'Crypto' : 'Fiat'}</StyledTableCell>
                                        <StyledTableCell className={tx.totalAmount <= 0 ? classes.redField : classes.greenField}>{tx.totalAmount}</StyledTableCell>
                                    </TableRow>)
                                })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                    <button disabled={totalAmount <= 0 || Web3.utils.fromWei(totalAmount ? totalAmount : '0', 'gwei') * 1000 > parseFloat(txData[0]?.totalAmount)} onClick={() => setShowConfirm(true)} className="btn-main mt-20">Claim Total USDC</button>
                      <Dialog
                        open={showConfirm}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className='alert-modal'
                      >
                        <div className='checkout'>
                          <div className='maincheckout'>
                            <button
                              className='btn-close'
                              onClick={() => setShowConfirm(false)}
                            >
                              x
                            </button>
                            <div className='heading'>
                              <h3>{t("Claim Total USDC")}</h3>
                            </div>
                            <p>
                              <span>You are about to claim <b>{(Web3.utils.fromWei(totalAmount ? totalAmount : '0', 'gwei') * 1000).toFixed(3)}</b> USDC?</span>
                            </p>
                            <div className={classes.transferButtonBlock}>
                              <button
                                className="btn-main"
                                style={{margin: 10}}
                                onClick={()=> handleClaim()}
                              >
                                {t("Confirm")} 
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog>
                </section>
                <Footer />
            </LoadingOverlay>
        </div>
    )
}

export default Transactions;