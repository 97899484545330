import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button, Typography } from '@material-ui/core';
import useStyles from "../../styles/components/Servererror/modalError";
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';

function SessionTimeoutModal({open, setOpen}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    dispatch(actions.setIsTimeout(false));
    localStorage.removeItem('_user');
		window.location.href = '/';
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className='alert-modal'
    >
      <div className={classes.sessionRoot}>
        <div className={classes.topBlock}>
            <HighlightOffIcon className={classes.icon} onClick={() => { 
              onClose();
            }} />
        </div>  
        <div className={classes.bottomBlock}>
            <Typography className={classes.noSessionTitle}>
              {"Session timeout!"}
            </Typography>
            <div className={classes.inputBlock}>
              <Button className={classes.igwClaimSubmit} color="primary" variant="contained" onClick={onClose} >{'OK'}</Button>
            </div>
        </div>
      </div>
    </Dialog>
  )
}

export default SessionTimeoutModal;