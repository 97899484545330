import Dialog from '@material-ui/core/Dialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { Button, Typography } from '@material-ui/core';
import useStyles from "../../styles/components/Servererror/modalError";

function EpicRegisterResultModal({open, onClose, isSuccess, errMsg}) {
  const classes=useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className='alert-modal'
    >
      <div className={classes.root}>
        <div className={classes.topBlock}>
            {!isSuccess ? <HighlightOffIcon className={classes.icon} onClick={() => { 
              onClose();
            }} /> : <CheckCircleOutlinedIcon className={classes.icon} onClick={() => { 
              onClose();
            }} />}
        </div> 
        <div className={classes.bottomBlock}>
					<Typography className={classes.igwTitle} style={{marginTop: 50, textAlign: 'center'}}>
						{isSuccess ? `Congratulations! Your Epic Game Store account has been linked to your wallet.`
						: `${errMsg}`}
					</Typography>
					<div className={classes.inputBlock} style={{marginTop: 50}}>
						<Button className={classes.igwClaimSubmit} color="primary" variant="contained" onClick={() => onClose()} >{'OK'}</Button>
					</div>
				</div>
      </div>
    </Dialog>
  )
}

export default EpicRegisterResultModal;
