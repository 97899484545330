import { useState, useEffect } from "react";
import Web3 from "web3";

import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';
import "react-datepicker/dist/react-datepicker.css";
import CompanyAbi from "../../../../artifacts/contracts/MarketplaceCompany.sol/MarketplaceCompany.json";
import SnapshotAbi from "../../../../artifacts/contracts/MarketplaceSnapshot.sol/MarketplaceSnapshot.json";
import * as actions from '../../../../store/actions/thunks';
import * as setActions from '../../../../store/actions';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../components/footer';
import Header from "../../menu/header";
import * as selectors from '../../../../store/selectors';
import Notification from "../../../../utils/functions/notification";
import { fetchCompanies } from "../../../../services/DataService";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import enableWeb3 from "../../../../utils/functions/enableWeb3";
import * as SnapshotService from "../../../../services/SnapshotService";
import * as AuthService from "../../../../services/AuthService";
import DatePicker from "react-datepicker";
import {
    fetchAvailableAssets,
    createMonthAsset
} from "../../../../services/AssetService";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#30166e',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

function Settings() {
    const web3 = new Web3(Web3.givenProvider);
    const signedUser = useSelector(selectors.user);
    const dispatch = useDispatch();

    const [isActive, setActive] = useState(false);
    const [commission, setCommission] = useState(0);
    const [royalty, setRoyalty] = useState(0);
    const [authorRoyalty, setAuthorRoyalty] = useState(0);
    const [isValid, setValid] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [walletAddress, setWalletAddress] = useState(null);
    const [vaultAmount, setVaultAmount] = useState(null);

    const [marketplaceTotalRevenues, setMarketplaceTotalRevenues] = useState(0);
    const [holdersTotalRevenues, setHoldersTotalRevenues] = useState(0);
    const [holdersRevenueClaimed, setHoldersRevenueClaimed] = useState(0);
    // eslint-disable-next-line

    const [assetDate, setAssetDate] = useState(null);
    const [asset, setAsset] = useState(0);
    const [assets, setAssets] = useState([]);

    const marketplaceAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const companyAddress = process.env.REACT_APP_COMPANY_CONTRACT;
    const snapshotAddress = process.env.REACT_APP_SNAPSHOT_CONTRACT;
    const companyContract = new web3.eth.Contract(CompanyAbi.abi, companyAddress);
    const snapshotContract = new web3.eth.Contract(SnapshotAbi.abi, snapshotAddress);

    const fetchCompanyData = () => {
        fetchCompanies().then(async (res) => {
            let companyData = [];
            for(let i=0; i<res.length; i++) {
                const data = await companyContract.methods.getInfo(res[i].wallet).call();
                const [commission, royalty, authorRoyalty] = Object.values(data).map(Number);
                companyData.push({
                    name: res[i].name,
                    address: res[i].wallet,
                    commission: commission / 10,
                    royalty: royalty / 10,
                    authorRoyalty: authorRoyalty / 10,
                });
            }
            setCompanies(companyData);
        }).catch((err) => {
            Notification(err, false);
        })
    }

    useEffect(() => {
        if(selectedCompany) {
            setCommission(selectedCompany.commission);
            setRoyalty(selectedCompany.royalty);
            setAuthorRoyalty(selectedCompany.authorRoyalty);
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (signedUser.id) {
            companyContract.methods.getInfo(signedUser.metamask).call().then(res => {
                setCommission(res[1] / 10);
                setRoyalty(res[2] / 10);
                setAuthorRoyalty(res[3] / 10);
            });

            snapshotContract.methods.claimable(marketplaceAddress).call().then(res => {
                setMarketplaceTotalRevenues(Web3.utils.fromWei(res, 'mwei'))
            })

            snapshotContract.methods.getTotalRevenuesForHolders(marketplaceAddress).call().then(res => {
                setHoldersTotalRevenues(Web3.utils.fromWei(res, 'mwei'))
            })

            snapshotContract.methods.getTotalClaimedRevenues().call().then(res => {
                setHoldersRevenueClaimed(Web3.utils.fromWei(res, 'mwei'))
            })

            fetchCompanyData();

            fetchAvailableAssets().then((res) => {
                setAssets(res);
            });
        }
    // eslint-disable-next-line
    }, [signedUser.id]);

    const onSubmit = async() => {
        dispatch(setActions.setLoading(true));
        AuthService.sessionCheck().then(async (res) => {
            dispatch(setActions.setLoading(false));
            try {
                setActive(true);
                const account = signedUser.metamask;
                await companyContract.methods.create(selectedCompany.address, parseFloat(commission)*10, parseFloat(royalty)*10, parseFloat(authorRoyalty)*10).send({from: account});
                setCompanies([]);
                Notification("Service Fee Updated", true);
                fetchCompanyData();
                setActive(false);
            } catch(error) {
                setActive(false);
                Notification("Failed to update commission of the company", false);
            }
        })
        .catch(err => {
            dispatch(setActions.setLoading(false));
            if(err.response?.status == '401') {
                dispatch(actions.setIsTimeout(true));
            }
        })
    }

    const handleCreateMonthAsset = () => {
        if(assetDate && asset) {
            dispatch(setActions.setLoading(true));
            AuthService.sessionCheck().then(async (res) => {
                dispatch(setActions.setLoading(false));
                setActive(true);
                createMonthAsset({
                    date: `${assetDate.getFullYear()}-${assetDate.getMonth() + 1}-${assetDate.getDate() < 10 ? '0': ''}${assetDate.getDate()}`,
                    assetId: asset
                }).then((res) => {
                    setActive(false);
                    Notification("Asset added for selected month", true);
                    setAssetDate(null);
                    setAsset(0);
                }).catch(error => {
                    let msg = (typeof error === 'object') ? error.message : error;
                    setActive(false);
                    Notification(msg, false);
                })
            })
            .catch(err => {
                dispatch(setActions.setLoading(false));
                if(err.response?.status == '401') {
                    dispatch(actions.setIsTimeout(true));
                }
            })
        }
        else {
            Notification('Date and Asset field should not be empty.', false);
        }
    }

    const handleCommission = async(value) => {
        setCommission(value);
        if(value.toString().split('.')[1]?.length > 1 || value === '' ) {
            setValid(false);
        } else {
            setValid(true);
        }
    }

    const handleRoyalty = async(value) => {
        setRoyalty(value);
        if(value.toString().split('.')[1]?.length > 1 || value === '' ) {
            setValid(false);
        } else {
            setValid(true);
        }
    }

    const handleAuthorRoyalty = async(value) => {
        setAuthorRoyalty(value);
        if(value.toString().split('.')[1]?.length > 1 || value === '' ) {
            setValid(false);
        } else {
            setValid(true);
        }
    }

    const onUpdateVault = async() => {
        dispatch(setActions.setLoading(true));
        AuthService.sessionCheck().then(async (res) => {
            dispatch(setActions.setLoading(false));
            setActive(true);
            const enabled = await enableWeb3();
            if (!enabled) {
                Notification('Waiting for metamask sign in.', false);
                return
            }
            const account = signedUser.metamask;
            try {
                const amount = Web3.utils.toWei(vaultAmount, 'mwei');
                await snapshotContract.methods.manualUpdateVault(walletAddress, amount).send({from: account});
                await SnapshotService.addSnapshot({wallet: walletAddress, balance: vaultAmount});
                setActive(false);
                setWalletAddress('');
                setVaultAmount('');
                Notification(`You updated the holders equity.`, true);
            } catch (error) {
                let msg = (typeof error === 'object') ? error.message : error;
                setActive(false);
                Notification(msg, false);
            }
        })
        .catch(err => {
            dispatch(setActions.setLoading(false));
            if(err.response?.status == '401') {
                dispatch(actions.setIsTimeout(true));
            }
        })
    }

    return (
        <div>
            <Header className=""/>
            <ReactNotifications />
            <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
                <div className='mainbreadcumb'>
                    <div className='container'>
                        <div className='row m-10-hor'>
                            <div className='col-12'>
                                <h1 className='text-center'>Settings</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LoadingOverlay
                active={isActive}
                spinner
                text="Keep Waiting..."
                className='centeredOverlay'>
                <section className='container'>
                    <div className="row">
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell scope="col">Company Name</StyledTableCell>
                                    <StyledTableCell scope="col">Wallet Address</StyledTableCell>
                                    <StyledTableCell scope="col">Commission</StyledTableCell>
                                    <StyledTableCell scope="col">Royalty</StyledTableCell>
                                    <StyledTableCell scope="col">Author Royalty</StyledTableCell>
                                </TableRow>
                                <TableRow></TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                companies.map((company, index) => (
                                <TableRow key={index} onClick={() => setSelectedCompany(company)}>
                                    <StyledTableCell style={{cursor: 'pointer', fontWeight: company.name === selectedCompany?.name ? 'bold' : 'lighter'}}>{company.name}</StyledTableCell>
                                    <StyledTableCell style={{cursor: 'pointer', fontWeight: company.name === selectedCompany?.name ? 'bold' : 'lighter'}}>{company.address}</StyledTableCell>
                                    <StyledTableCell style={{cursor: 'pointer', fontWeight: company.name === selectedCompany?.name ? 'bold' : 'lighter'}}>{company.commission}</StyledTableCell>
                                    <StyledTableCell style={{cursor: 'pointer', fontWeight: company.name === selectedCompany?.name ? 'bold' : 'lighter'}}>{company.royalty}</StyledTableCell>
                                    <StyledTableCell style={{cursor: 'pointer', fontWeight: company.name === selectedCompany?.name ? 'bold' : 'lighter'}}>{company.authorRoyalty}</StyledTableCell>
                                </TableRow>
                                ))
                            }
                            </TableBody>
                        </Table>
                    </div>
                    {selectedCompany && 
                        <form id="form-create-item" className="form-border" action="#">
                            <div className="field-set row mt-5">
                                <div className="col-md-3">
                                    <h5>Commission (%)</h5>
                                    <input type="number" step={0.1} className="form-control" placeholder={`Eg: 30%`} onChange={(event) => handleCommission(event.target.value)} value={commission}/>
                                </div>
                                <div className="col-md-3">
                                    <h5>Royalty (%)</h5>
                                    <input type="number" step={0.1} className="form-control" value={royalty} onChange={(event) => handleRoyalty(event.target.value)}/>
                                </div>
                                <div className="col-md-3">
                                    <h5>Author Royalty (%)</h5>
                                    <input type="number" step={0.1} className="form-control" value={authorRoyalty} onChange={(event) => handleAuthorRoyalty(event.target.value)}/>
                                </div>
                                <div className="col-md-3">
                                    <input type="button" id="submit" className="btn-main" style={{marginTop: 32}} value="Submit" onClick={onSubmit} disabled={royalty === '' || commission === '' || authorRoyalty === ''}/>
                                </div>
                            </div>
                        </form>
                    }
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <p><strong>Marketplace Total revenues until 31/12/2029:</strong> {marketplaceTotalRevenues} USDC</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Marketplace Total revenues for Holders:</strong> {holdersTotalRevenues} USDC</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Marketplace Holders Revenues claimed:</strong> {holdersRevenueClaimed} USDC</p>
                        </div>
                        <div className='col-12'>
                            <p><strong>Marketplace Holders Revenues remaining:</strong> {Number((holdersTotalRevenues - holdersRevenueClaimed).toFixed(10))} USDC</p>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-4'>
                            <span>Wallet Address</span>
                            <input type="text" className="form-control" value={walletAddress} onChange={(event) => setWalletAddress(event.target.value)}/>
                        </div>
                        <div className='col-4'>
                            <span>Amount</span>
                            <input type="number" step={0.1} className="form-control" value={vaultAmount} onChange={(event) => setVaultAmount(event.target.value)}/>
                        </div>
                        <div className='col-4'>
                            <input type="button" className="btn-main" value="Submit" style={{marginTop: 32}} onClick={() => onUpdateVault()} />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-4'>
                            <span>Select Date</span>
                            <DatePicker
                                selected={assetDate}
                                onChange={(date) => setAssetDate(date)}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                style={{padding: 10}}
                                className="form-control"
                            />
                        </div>
                        <div className="col-4">
                            <span>Select Asset</span>
                            <select
                                value={asset}
                                onChange={(e) => setAsset(e.target.value)}
                                className="form-control"
                            >
                                <option value={0}>-- Select Asset --</option>
                                {assets.map((item, index) => (
                                    <option key={index} value={item.id}>{item.title}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <input type="button" className="btn-main" value="Add Asset" style={{marginTop: 32}} onClick={() => handleCreateMonthAsset()} />
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
            <Footer />
        </div>
    )
}

export default Settings